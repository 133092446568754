import './App.css';
import Dashboard from "./components/Dashboard";
import { useSelector } from "react-redux";
import { selectUserState, UserState } from "./state/GeneralSlice";
import Login from "./components/content/Login";
import LoggingIn from "./components/LoggingIn";

function App() {
    const userState = useSelector(selectUserState)
    return <>
        {(userState === UserState.LOGGED_OUT || userState === UserState.LOGGING_ERROR) && <Login />}
        {userState === UserState.LOGGING_IN && <LoggingIn />}
        {userState === UserState.LOGGED_IN && <Dashboard />}
    </>;
}

export default App;
