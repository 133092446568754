import { ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/solid'
import { useCallback, useMemo, useState } from 'react';

const maxPages = 7;

export default function Pagination({ itemsCount, itemsPerPage, onPageChanged }) {
    const [currentPage, setCurrentPage] = useState(1);
    const pagesCount = Math.ceil(itemsCount / itemsPerPage);

    const pages = useMemo(() => {
        if (pagesCount <= maxPages) return [...Array(pagesCount).keys()].map(v => v + 1);

        const startCenter = 4;
        const endCenter = pagesCount - 3;
        if (currentPage <= startCenter) {
            const p = [...Array(maxPages - 1).keys()].map(v => v + 1);
            p.push('...');
            return p;
        }
        if (currentPage >= endCenter) {
            const end = pagesCount - maxPages + 1;
            const p = [];
            for (var i = pagesCount - 1; i >= end; i--) {
                p.push(i);
            }
            p.push('...');
            return p.reverse();
        }

        const p = [];
        p.push('...');
        for (var k = currentPage - 2; k <= currentPage + 2; k++) {
            p.push(k);
        }
        p.push('...');
        return p;
    }, [currentPage, pagesCount]);

    const changePage = useCallback((pageNumber) => {
        if (isNaN(pageNumber)) return;
        if (pageNumber < 1) pageNumber = 1;
        if (pageNumber > pagesCount) pageNumber = pagesCount;
        setCurrentPage(pageNumber);
        onPageChanged(pageNumber);
    }, [onPageChanged, pagesCount]);

    return (
        <div className="flex-shrink">
            <nav className="flex items-center justify-between border-t border-blue-lightest px-4 sm:px-0">
                <div className="-mt-px flex w-0 flex-1">
                    <a
                        href="#"
                        onClick={() => changePage(1)}
                        className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-blue-lightest hover:text-white"
                    >
                        <ChevronDoubleLeftIcon className="mr-3 w-5" aria-hidden="true" />
                        First
                    </a>
                </div>
                <a
                    href="#"
                    onClick={() => changePage(currentPage - 1)}
                    className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-blue-lightest hover:text-white"
                >
                    <ChevronLeftIcon className="mr-3 h-5 w-5" aria-hidden="true" />
                </a>
                {/*Show pages*/}
                <div className="hidden md:-mt-px md:flex">
                    {
                        pages.map((p, idx) => {
                            return <a
                                key={idx}
                                href="#"
                                onClick={() => changePage(p)}
                                className={"inline-flex items-center border-t-4 px-4 pt-4 text-sm font-medium " + (p === currentPage
                                    ? "border-white text-white"
                                    : "border-transparent text-blue-lightest hover:text-white")}
                            >
                                {p}
                            </a>
                        })
                    }
                </div>
                {/*Show current page*/}
                <div className="flex md:-mt-px md:hidden">
                    {
                        <p
                            className={"inline-flex items-center border-t-4 px-4 pt-4 text-sm font-medium border-white text-white"}
                        >
                            {currentPage}
                        </p>
                    }
                </div>
                <a
                    href="#"
                    onClick={() => changePage(currentPage + 1)}
                    className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-blue-lightest hover:text-white"
                >
                    <ChevronRightIcon className="ml-3 h-5 w-5" aria-hidden="true" />
                </a>
                <div className="-mt-px flex w-0 flex-1 justify-end">
                    <a
                        href="#"
                        onClick={() => changePage(pagesCount)}
                        className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-blue-lightest hover:text-white"
                    >
                        Last
                        <ChevronDoubleRightIcon className="ml-3 w-5" aria-hidden="true" />
                    </a>
                </div>
            </nav >
        </div >
    )
}
