import { createListenerMiddleware } from "@reduxjs/toolkit";
import { getUserConfig, loggedIn } from "./GeneralSlice";
import { fetchDocuments } from "./KnowledgeSlice";
import {fetchInfo} from "./UserSlice";
import Api, { DEFAULT_COLLECTION } from "./api";
import { resetHistory } from "./ChatSlice";

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
    actionCreator: loggedIn,
    effect: async (action, listenerApi) => {
        listenerApi.dispatch(fetchDocuments(DEFAULT_COLLECTION))
        listenerApi.dispatch(getUserConfig());
        listenerApi.dispatch(fetchInfo());
    },
})

listenerMiddleware.startListening({
    actionCreator: resetHistory,
    effect: async (action, listenerApi) => {
        Api.abortAsk()
    },
})

export default listenerMiddleware;