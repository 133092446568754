import React, { useCallback, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { TrashIcon, ArrowSmallUpIcon, ExclamationCircleIcon, CheckIcon } from "@heroicons/react/24/solid";
import { useDispatch } from "react-redux";
import Api, { DEFAULT_COLLECTION } from "../../../state/api";
import { fetchDocuments } from "../../../state/KnowledgeSlice";

const fileTypes = ["PDF", "MD", "TXT"];

const FileState = {
    SELECTED: "selected",
    UPLOADING: "uploading",
    ERROR: "error",
    DONE: "done"
}

function DragDrop() {
    const dispatch = useDispatch();
    const [dragClasses, setDragClasses] = useState("")
    const [files, setFiles] = useState([]);
    const [fileStates, setFileStates] = useState({});

    useEffect(() => {
        setFileStates((prevState) => files.map((el, idx) => {
            const prev = prevState[idx];
            return prev ? prev : FileState.SELECTED
        }))
    }, [files])

    const handleChange = useCallback((file) => {
        const newFiles = Object.values(file);
        setFiles((prev) => {
            if (prev) {
                return [...prev, ...newFiles];
            }
            else
                return newFiles
        });
    }, [setFiles]);

    const handleUpload = useCallback(async (event) => {
        event.stopPropagation();
        event.preventDefault();
        const promises = []
        files.forEach((item, idx) => {
            if (fileStates[idx] !== FileState.SELECTED)
                return;
            setFileStates((prevState) => {
                const tmp = [...prevState];
                tmp[idx] = FileState.UPLOADING;
                return tmp;
            })
            const promise = Api.uploadDocument(item);
            promises.push(promise);
            promise.then(() => {
                setFileStates((prevState) => {
                    const tmp = [...prevState];
                    tmp[idx] = FileState.DONE;
                    return tmp;
                });
            })
                .catch(() => {
                    setFileStates((prevState) => {
                        const tmp = [...prevState];
                        tmp[idx] = FileState.ERROR;
                        return tmp;
                    });
                })
        })

        if (promises.length > 0) {
            await Promise.all(promises)
            dispatch(fetchDocuments(DEFAULT_COLLECTION))
        }
    }, [dispatch, files, fileStates, setFileStates]);

    return (
        <div className="text-white">
            <FileUploader multiple={true} handleChange={handleChange} name="file" types={fileTypes} maxSize={100} minSize={0}>
                <div className="flex items-center justify-center w-full">
                    <label htmlFor="dropzone-file"
                        className={"flex flex-col items-center justify-center w-full h-64 border-2 border-white border-dashed rounded-lg cursor-pointer hover:bg-blue-light bg-blue " + dragClasses}
                        onDragEnter={() => setDragClasses("bg-blue-light")}
                        onDragLeave={() => setDragClasses("")}
                    >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6 pointer-events-none">
                            <svg aria-hidden="true" className="w-10 h-10 mb-3" fill="none"
                                stroke="currentColor" viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path>
                            </svg>
                            <p className="mb-2 text-sm"><span
                                className="font-semibold">Click to upload</span> or drag and drop</p>
                            <p className="text-xs">PDF - TXT - MarkDown</p>
                        </div>
                        <input name="dropzone-file" id="dropzone-file" type="file" className="hidden" accept="application/pdf" multiple={true} />
                    </label>
                </div>
            </FileUploader>
            {files && files.length > 0 && <div className="px-1 pt-4 text-xs w-full">
                <p className="font-bold">Selected files:</p>
                {files && Object.values(files).map((item, idx) => {
                    return <div key={idx} className="flex">
                        <p className="truncate pt-2 flex-grow italic">
                            {idx + 1} - {item.name}
                        </p>
                        {fileStates[idx] === FileState.SELECTED && <TrashIcon className="ml-1 w-5 h-5 text-orange-pastel cursor-pointer" onClick={() => { setFiles(files.filter((el) => el !== item)) }} />}
                        {fileStates[idx] === FileState.UPLOADING && <ArrowSmallUpIcon className="ml-1 w-5 h-5 text-blue-pastel cursor-pointer" />}
                        {fileStates[idx] === FileState.DONE && <CheckIcon className="ml-1 w-5 h-5 text-green-pastel cursor-pointer" />}
                        {fileStates[idx] === FileState.ERROR && <ExclamationCircleIcon className="ml-1 w-5 h-5 text-orange-pastel cursor-pointer" />}
                    </div>
                })
                }
                <form onSubmit={handleUpload} className="text-center mt-4">
                    {fileStates.filter((el) => el === FileState.SELECTED).length > 0 && <input type="submit" value="UPLOAD" className={"bg-blue p-2 w-32 rounded hover:bg-blue-light font-bold"} />}
                </form>
            </div>
            }
        </div>
    );
}

export default DragDrop;