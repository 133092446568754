import {TrashIcon} from "@heroicons/react/24/solid";
import {useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";
import {historySelector, resetHistory} from "../../../state/ChatSlice";

export default function Reset() {
    const dispatch = useDispatch();
    const history = useSelector(historySelector);
    const onClick = useCallback(() => {
        dispatch(resetHistory())
    }, [dispatch])
    return <div className="absolute top-2 left-2 h-8 w-8">
        {history.length > 0 && <TrashIcon className="fill-blue-light hover:fill-blue-lightest cursor-pointer" onClick={onClick}/>}
    </div>
}
