import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import Api from "./api";
import {AvatarName} from "./GeneralSlice";

export const historySelector = (state) => state[chatSlice.name].history;
export const sendersSelector = (state) => state[chatSlice.name].senders;
export const pendingRequestSelector = (state) => state[chatSlice.name].pendingRequest;

export function collapseHistory(history, senders){
    const result = [];
    let currentSender = undefined;
    let currentMessages = [];
    history.forEach((item) => {
        if(currentSender && item.sender !== currentSender){
            result.push({
                sender: {...senders[currentSender], key: currentSender},
                messages: currentMessages
            })
            currentSender = undefined;
            currentMessages = [];
        }
        currentMessages.push({text: item.message, sources: item.sources});
        currentSender = item.sender;
    })
    if(currentMessages && currentSender){
        result.push({
            sender: {...senders[currentSender], key: currentSender},
            messages: currentMessages
        });
    }
    return result;
}

const ask = createAsyncThunk(
    'ask',
    async (input, {dispatch, getState}) => {
        const state = getState();
        const history = historySelector(state);
        const senders = sendersSelector(state);
        const collapsed = collapseHistory(history, senders);
        const formattedHistory = [];
        const conversation = [undefined, undefined];
        let assistantMessages = "";
        collapsed.forEach((item) => {
            if(item.sender.key === AvatarName.HUMAN){
                if(conversation[0] && assistantMessages){
                    conversation[1] = assistantMessages;
                    // Append chat precedente
                    formattedHistory.push([...conversation]);
                    conversation[0] = undefined;
                    conversation[1] = undefined;
                }
                assistantMessages = "";
                conversation[0] = item.messages.map((el) => el.text).join("\n");
            }
            else {
                // Assistant o Agent
                assistantMessages = item.messages.map((el) => el.text).join("\n");
            }
        })
        if(conversation[0] && assistantMessages){
            conversation[1] = assistantMessages;
            // Append chat precedente
            formattedHistory.push([...conversation]);
        }
        return await Api.ask(input, formattedHistory, (data) => {
            dispatch(updatePendingMessage(data))
        });
    }
)

export const chatSlice = createSlice({
        name: 'chat',
        initialState: {
            pendingRequest: false,
            history: [
            ],
            senders: {
                human: {
                    name: "You"
                },
                vicuna: {
                    name: "Vicuña"
                },
                agent1: {
                    name: "Vicuña Writer"
                },
                agent2: {
                    name: "Vicuña Speaker"
                },
                agent3: {
                    name: "Vicuña DJ"
                }
            }
        },
        reducers: {
            resetHistory: (state, action) => {
                state.history = [];
            },
            updatePendingMessage: (state, action) => {
                const lastMessagePosition = state.history.length - 1;
                state.history[lastMessagePosition] = {
                    ...state.history[lastMessagePosition],
                    message: action.payload.answer ? state.history[lastMessagePosition].message + action.payload.answer : state.history[lastMessagePosition].message,
                    sources: action.payload.sources ? action.payload.sources : state.history[lastMessagePosition].sources
                }
                return state
            }
        },
        extraReducers: (builder) => {
            builder
                .addCase(ask.pending, (state, {meta}) => {
                    state.history.push({
                        sender: AvatarName.HUMAN,
                        message: meta.arg
                    })
                    state.history.push({
                        sender: "vicuna",
                        message: "",
                        sources: undefined
                    })
                    state.pendingRequest = true;
                })
                .addCase(ask.fulfilled, (state, {meta}) => {
                    state.pendingRequest = false;
                })
                .addCase(ask.rejected, (state, action) => {
                    state.pendingRequest = false;
                })
        },
    }
);

export const { resetHistory, updatePendingMessage } = chatSlice.actions;
export {ask};

export default chatSlice.reducer;