import {API_URL} from "../../../state/api";
import Modal from "../Modal";
import Api from "../../../state/api";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/24/solid";

export default function ShowSources({show, onClose, sources}) {

    return (
        <Modal show={show} onClose={onClose}>
            <div className="text-white sm:max-w-6xl">
                {
                    sources && sources.map((el, idx) => {
                        return <div key={idx} className="text-sm my-2">
                            <div className="flex font-bold"><p className="mr-1">Source #{idx+1} - </p>
                                <a target="_blank" href={API_URL + "/collections/download-file?access_token="+Api.readToken()+"&file_id="+el.metadata?.source_file+"#page="+(parseInt(el.metadata.page)+1)} className="flex">OPEN<ArrowTopRightOnSquareIcon className="ml-1 w-4 h-5"/></a>
                            </div>
                            <ReactMarkdown remarkPlugins={[remarkGfm]} className="text-xs text-gray">{el.page_content}</ReactMarkdown>
                        </div>
                    })
                }
            </div>
        </Modal>
    )
}
