import { useDispatch, useSelector } from "react-redux";
import { setSortingDescending, setSortingProperty, sortingDescendingSelector, sortingPropertySelector } from "../../../state/KnowledgeSlice";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { useCallback } from "react";

export default function TableHeader({ name, center = false, classes = "" }) {
    const sortingProperty = useSelector(sortingPropertySelector);
    const sortingDescending = useSelector(sortingDescendingSelector);
    const dispatch = useDispatch();

    const property = name.toLowerCase();

    const onClick = useCallback((descending) => {
        dispatch(setSortingProperty(property));
        dispatch(setSortingDescending(descending));
    }, [dispatch, property])

    return <th
        scope="col"
        className={"px-3 py-3.5 text-sm font-semibold text-blue-lightest " + classes}
    >
        <div className={"flex gap-x-2 " + (center ? "justify-center" : "")}>
            <span>{name}</span>
            <a href="#">
                {property !== sortingProperty
                    ? <ChevronUpIcon onClick={() => onClick(true)} className="h-5 stroke-blue hover:stroke-blue-light" />
                    : (
                        sortingDescending
                            ? <ChevronUpIcon onClick={() => onClick(!sortingDescending)} className="h-5 stroke-white" />
                            : <ChevronDownIcon onClick={() => onClick(!sortingDescending)} className="h-5 stroke-white" />
                    )
                }
            </a>
        </div>
    </th>
}