import React, { useState } from 'react';
import Authenticate from './login/Authenticate';
import Register from './login/Register';
import { ReactComponent as Logo } from './../../logotipo.svg';
import Confirmation from './login/Confirmation';

export const LoginStatus = {
    AUTHENTICATE: 'authenticate',
    REGISTER: 'register',
    CONFIRMATION: 'confirmation',
    TWITTER: 'twitter',
    GITHUB: 'github'
}

const Switch = props => {
    const { test, children } = props
    // filter out only children with a matching prop
    return children.find(child => {
        return child.props.value === test
    })
}

export default function Login() {
    const [status, setStatus] = useState(LoginStatus.AUTHENTICATE);

    return <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 text-white" >
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <Logo className="mx-auto h-14 w-auto fill-white" />
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div className="bg-blue-dark px-6 py-12 shadow sm:rounded-lg sm:px-12">
                <Switch test={status}>
                    <Authenticate value={LoginStatus.AUTHENTICATE} onRegister={() => setStatus(LoginStatus.REGISTER)} />
                    <Register value={LoginStatus.REGISTER} onSubmit={() => setStatus(LoginStatus.CONFIRMATION)} onCancel={() => setStatus(LoginStatus.AUTHENTICATE)} />
                    <Confirmation value={LoginStatus.CONFIRMATION} />
                </Switch>
            </div>
        </div>
    </div>
}