import { useSelector } from "react-redux"
import { selectUserConfig } from "../../../state/GeneralSlice"
import ComboBox from "./ComboBox"

const models = [
    'text-davinci-003',
    'gpt-4',
    'gpt-3.5-turbo',
]

export default function ModelComboBox() {
    const model = useSelector((state) => selectUserConfig(state).llm_model);
    return <ComboBox label='Model' name='model' items={models} initialValue={model} />
}