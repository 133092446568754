import DragDropFile from "./DragDropFile";
import Modal from '../Modal';

export default function AddDocumentModal({ show, onClose }) {
    return (
        <Modal show={show} onClose={onClose}>
            <DragDropFile />
        </Modal>
    )
}
