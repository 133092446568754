import { createContext, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
    documentsCountSelector,
    selectLoading
} from "../../state/KnowledgeSlice";
import Pagination from "./knowledge/Pagination";
import ControlBar from "./knowledge/ControlBar";
import Table from "./knowledge/Table";
import Loading from "./Loading";
import SearchBar from "./knowledge/SearchBar";

export const DOCUMENTS_PER_PAGE = 20;

export const KnowledgeContext = createContext()

export default function KnowledgeBase() {
    const documentsCount = useSelector(documentsCountSelector);
    const loading = useSelector(selectLoading);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);

    const contextData = useMemo(() => {
        return {
            selectedDocuments,
            setSelectedDocuments,
            pageNumber,
            setPageNumber
        }
    }, [selectedDocuments, setSelectedDocuments, pageNumber, setPageNumber])

    return (
        <KnowledgeContext.Provider value={contextData}>
            <div className="py-2 mx-auto max-w-7xl flex flex-col items-stretch h-full max-h-full">
                <ControlBar />
                {loading && <Loading />}
                {!loading && <>
                    <div className="mt-4 mb-2">
                        <SearchBar />
                    </div>
                    <Table />
                    <Pagination itemsCount={documentsCount} itemsPerPage={DOCUMENTS_PER_PAGE} onPageChanged={(num) => setPageNumber(num)} />
                </>}
            </div>
        </KnowledgeContext.Provider>
    );
}
