import {Fragment, useEffect, useState} from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
    Bars3Icon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import Sidebar from "./Sidebar";
import Avatar from "./Avatar";
import Content from "./Content";
import { ReactComponent as Logo } from './../logotipo.svg';
import Div100vh from "react-div-100vh";
import {useLocation} from "react-router-dom";

export default function Dashboard() {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const location = useLocation()?.pathname;

    useEffect(() => {
        setSidebarOpen(false);
    }, [location, setSidebarOpen])

    return (
        <>
            <Div100vh className="h-screen max-h-screen flex flex-col">
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-900/80" />
                        </Transition.Child>

                        <div className="fixed inset-0 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="relative mr-16 flex w-full max-w-max flex-1">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                            <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                                                <span className="sr-only">Close sidebar</span>
                                                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <Sidebar />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col">
                    <Sidebar />
                </div>

                <div className="top-0 z-40 flex items-center gap-x-6 bg-blue-dark px-4 py-4 shadow-sm sm:px-6 lg:hidden h-[4rem]">
                    <button type="button" className="-m-2.5 p-2.5 text-gray-400 lg:hidden" onClick={() => setSidebarOpen(true)}>
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6 stroke-white" aria-hidden="true" />
                    </button>
                    <Logo className="flex-1 h-8 fill-white"/>
                    <Avatar />
                </div>

                <main className="lg:pl-52 text-white flex-grow max-h-[calc(100%-4rem)] lg:max-h-full">
                    <div className="p-1 lg:px-4 h-full max-h-full">
                        <Content/>
                    </div>
                </main>
            </Div100vh>
        </>
    )
}
