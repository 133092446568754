import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    title: string;
    showIcon: boolean;
}

function Buttoon({ children, ...props }: ButtonProps): React.JSX.Element {
    if (!props.type) props.type = 'button';
    return (
        <button {...props}
            className={"w-32 block rounded-md bg-blue px-3 py-2 text-center text-sm font-semibold text-white disabled:bg-gray hover:bg-blue-light " + props.className} >
            {children}
        </button>
    )
};

export default Buttoon;
