import { useCallback, useContext, useState } from "react";
import Api, { DEFAULT_COLLECTION } from "../../../state/api";
import { useDispatch } from "react-redux";
import { KnowledgeContext } from "../KnowledgeBase";
import AddDocumentModal from "./AddDocumentModal";
import { fetchDocuments } from "../../../state/KnowledgeSlice";
import AddNoteModal from "./AddNoteModal";
import Buttoon from "../../Buttoon";
import AddWebUrlModal from "./AddWebUrlModal";

export default function ControlBar() {
    const { selectedDocuments, setSelectedDocuments } = useContext(KnowledgeContext)
    const dispatch = useDispatch();

    const clearSelection = useCallback(() => {
        setSelectedDocuments([]);
    }, [setSelectedDocuments]);

    const deleteDocuments = useCallback(() => {
        Promise.all(selectedDocuments.map((element) => Api.deleteDocument(DEFAULT_COLLECTION, element.id+"_"+element.filename))).then(r => {
            dispatch(fetchDocuments(DEFAULT_COLLECTION))
            clearSelection();
        })
    }, [clearSelection, dispatch, selectedDocuments]);

    const [addDocumentActive, setAddDocumentActive] = useState(false)
    const [addNoteActive, setAddNoteActive] = useState(false)
    const [addWebUrlActive, setAddWebUrlActive] = useState(false)

    return <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-white">Knowledge Base</h1>
            <p className="mt-2 text-sm text-blue-lightest">
                Manage your knowledge base
            </p>
        </div>
        <div className="mt-4 inline-flex sm:ml-16 sm:mt-0 sm:flex-none gap-2">
            {selectedDocuments.length > 0
                ? (
                    <>
                        <Buttoon
                            onClick={clearSelection}
                            disabled={selectedDocuments.length === 0}
                        >
                            Clear Selection
                        </Buttoon>
                        <Buttoon
                            onClick={deleteDocuments}
                            disabled={selectedDocuments.length === 0}
                            className="bg-orange hover:bg-orange-pastel"
                        >
                            Delete
                        </Buttoon>
                    </>
                )
                : (
                    <>
                        <Buttoon onClick={() => setAddDocumentActive(true)} >Add Document</Buttoon>
                        <AddDocumentModal show={addDocumentActive} onClose={() => setAddDocumentActive(false)} />

                        <Buttoon onClick={() => setAddNoteActive(true)} >Add Note</Buttoon>
                        <AddNoteModal show={addNoteActive} onClose={() => setAddNoteActive(false)} />

                        <Buttoon onClick={() => setAddWebUrlActive(true)} >Add Web URL</Buttoon>
                        <AddWebUrlModal show={addWebUrlActive} onClose={() => setAddWebUrlActive(false)} />
                    </>
                )
            }
        </div>
    </div>
}