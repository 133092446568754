import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/24/outline'

export default function Confirmation() {

    return <div className="text-center">
        <CheckCircleIcon className="h-12 w-12 text-green mx-auto mb-6" aria-hidden="true" />
        <h2>
            Your registration has been successfully sumbitted.
        </h2>
        <h2>
            Your account will be activated soon.
        </h2>
        <h2>
            You will receive an email as soon as possible.
        </h2>
        <h2>
            Thank you!
        </h2>
    </div>
}