import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { act } from 'react-dom/test-utils';
import Api from "./api"

const login = createAsyncThunk(
    'login',
    async ({ username, password }, { dispatch }) => {
        const response = await Api.login(username, password);
        if (response.status === 401)
            dispatch(generalSlice.actions.loggedOut())
        else
            dispatch(generalSlice.actions.loggedIn())
        return response.data
    }
)

const logout = createAsyncThunk(
    'logout',
    async (_, { dispatch }) => {
        await Api.logout();
        dispatch(checkAndRefreshToken())
        return;
    }
)

const checkAndRefreshToken = createAsyncThunk(
    'checkAndRefreshToken',
    async (_, { dispatch }) => {
        // TODO: Non tanto bello
        const response = await Api.checkAndRefreshToken()
        if (response)
            dispatch(generalSlice.actions.loggedIn())
        else
            dispatch(generalSlice.actions.loggedOut())
        return response
    }
)

const getUserConfig = createAsyncThunk(
    'getUserConfig',
    async (_, { dispatch }) => {
        const response = await Api.getUserSettings();
        dispatch(generalSlice.actions.updateModel(response.llm_model));
        dispatch(generalSlice.actions.updateRetrieval(response.retrieval_top_k));
        return response;
    }
)

export const AvatarName = {
    HUMAN: "human",
    VICUNA: "vicuna",
    AGENT1: "agent1",
    AGENT2: "agent2",
    AGENT3: "agent3",
}

export const UserState = {
    LOGGING_IN: "logging_in",
    LOGGING_ERROR: "logging_error",
    LOGGED_IN: "logged_in",
    LOGGED_OUT: "logged_out"
}

const hasToken = true;

const initialState = {
    userState: hasToken ? UserState.LOGGING_IN : UserState.LOGGED_OUT,
    userConfig: {
        "llm_model": '',
        "retrieval_top_k": '',
    }
};

export const generalSlice = createSlice({
    name: 'general',
    initialState: initialState,
    reducers: {
        loggedIn: () => { },
        loggedOut: () => { },
        updateModel: (state, action) => { state.userConfig.llm_model = action.payload },
        updateRetrieval: (state, action) => { state.userConfig.retrieval_top_k = action.payload },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state, action) => {
                state.userState = UserState.LOGGING_IN;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.userState = UserState.LOGGED_IN;
            })
            .addCase(login.rejected, (state, action) => {
                state.userState = UserState.LOGGING_ERROR;
            })
        builder
            .addCase(checkAndRefreshToken.fulfilled, (state, action) => {
                state.userState = action.payload ? UserState.LOGGED_IN : UserState.LOGGED_OUT;
            })
            .addCase(checkAndRefreshToken.rejected, (state, action) => {
                state.userState = UserState.LOGGED_OUT;
            })
        builder
            .addCase(getUserConfig.fulfilled, (state, action) => {
                state.userConfig = action.payload
            })
    },
}
);
export const selectUserState = (state) => state[generalSlice.name].userState;
export const selectUserConfig = (state) => state[generalSlice.name].userConfig;

export { checkAndRefreshToken, login, logout, getUserConfig };
export const { loggedIn, loggedOut, updateModel, updateRetrieval } = generalSlice.actions;

export default generalSlice.reducer;